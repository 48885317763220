/* NewCommScreen.module.css */
.voice-slider-labels span {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 33.3333%;
}
.voice-slider-labels span:first-child {
    text-align: left;
}
.voice-slider-labels span:last-child {
    text-align: right;
}